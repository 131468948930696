@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

/* FONTS */

.poppins{
    font-family: 'poppins', sans-serif;
}
.montserrat{
    font-family: 'montserrat', sans-serif;
}
.uppercase{
    text-transform: uppercase;
}
.text-balance{
    text-wrap: balance;
}

/* GENERAL */

.mh-100{
    min-height: 100vh;
}
.navbar-margin{
    margin-top: 13vh;
}
.box-shadow{
    box-shadow: 2px 4px 8px rgba(0,0,0,0.3);
}

/* FOOTER */

.footer{
    background: #242424;
    color: white;
}
.icon-container{
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 100px;
    z-index: 50;
    aspect-ratio: 1;
}
.icon-container img{
    width: 100%;
    transition: 0.3s;
}
.icon-container img:hover{
    cursor: pointer;
    transform: scale(1.1);
}

/* NAVBAR */

.navbar{
    height: 10vh;
    background: #242424;
    position: fixed;
    top: 0;
    z-index: 100;
}
.navbar .items{
    display: flex;
    align-items: center;
    gap: 4rem;
    margin: 0;
}
.logo{
    transition: 0.4s;
}
.logo:hover{
    transform: scale(1.02);
}
.navbar .items .item{
    color: white;
    list-style: none;
    position: relative;
    width: 120px;
}
.navbar .items .item .submenu{
    position: absolute;
    background: #242424;
    min-width: 100%;
    top: 82px;
    padding: 0 15px;
    text-align: center;

    padding: 1px 0;
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 0.5s;
}
.submenu-content{
    min-height: 0;
    background: #242424;
    color: white;
}
.navbar .items .item .submenu:hover{
    grid-template-rows: 1fr;
}
.navbar .items .item:hover .submenu{
    grid-template-rows: 1fr;
}
.navbar .items .item .submenu ul li{
    list-style: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}
.navbar .items .item .submenu ul li a{
    text-decoration: none;
    color: white;
}
.navbar #home-button{
    text-decoration: none;
    color: white;
}
.navbar .items .item .submenu ul li a:hover, .navbar .items .item:hover, .navbar #home-button:hover{
    color: yellow;
}

/* HAMBURGER BUTTON */

#burger{
    display: none;
}
.burger {
    position: relative;
    width: 40px;
    height: 30px;
    background: transparent;
    cursor: pointer;
    display: block;
    transform: scale(0.8);
}
.burger input {
    display: none;
}
.burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
.burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
}
.burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
}
.burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
}
#burger:checked ~ .burger span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
}
#burger:checked ~ .burger span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
}
#burger:checked ~ .burger span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 5px;
}

/* HOME */

/* CAROUSEL */
.carousel{
    width: 100% !important;
    height: 700px !important;
    box-shadow: 2px 4px 8px rgba(0,0,0,0.3);
    border-radius: 15px;
}
.carousel-inner{
    height: 100%;
}
.carousel-item{
    height: 100%;
}

.underline{
    position: relative;
    width: fit-content;
    text-shadow: 2px 4px 8px rgba(0,0,0,0.3);
}
.underline::before{
    position: absolute;
    width: 150%;
    background: black;
    height: 2px;
    bottom: -24px;
    left: 0;
    content: '';
}
.underline::after{
    position: absolute;
    width: 150%;
    background: yellow;
    height: 2px;
    bottom: -20px;
    left: 0;
    content: '';
}
.card-container{
    margin: 100px 0;
    min-height: 600px;
    flex-wrap: wrap;
}
.product-card{
    border-radius: 15px;
    min-height: 420px;
    box-shadow: 2px 4px 8px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 300px;
}
.card-content{
    display: none;
}
.product-card a{
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    font-size: 14px;
}
.image-card-container{
    overflow: hidden;
}
.product-card img{
    height: 270px !important;
    border-bottom: 1px solid lightgray;
    transition: .5s;
}
.product-card img:hover{
    scale: 1.1;
}
/* PRODUCT DETAIL */
.colors-container span{
    height: 20px;
    aspect-ratio: 1;
}
.colors-container :nth-child(2){
    background: red;
}
.colors-container span:nth-child(3){
    background: blue;
}
.colors-container span:nth-child(4){
    background: green;
}
.colors-container span:nth-child(5){
    background: yellow;
}
.colors-container span:nth-child(6){
    background: orange;
}
.heading{
    color: rgb(144, 211, 233);
    text-decoration: underline;
}
.product-detail-container h1::before{
    position: absolute;
    bottom: -20px;
    width: 100%;
    content: '';
    height: 4px;
    background: rgba(0, 0, 0, 1);
}
.product-detail-container h1::after{
    position: absolute;
    bottom: -24px;
    width: 100%;
    transform: translateX(-100%);
    content: '';
    height: 4px;
    background: rgba(238, 255, 0, 1);
}

/* TRANSITION */

.slide-in{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #e6e4e6;
    transform-origin: bottom;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slide-out{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #e6e4e6;
    transform-origin: top;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width:1200px){
    .carousel{
        height: auto !important;
    }
}
@media(max-width:1050px){
    .navbar .items .item{
        width: fit-content;
    }
}
@media(min-width:900px){
    .burger{
        display: none;
    }
}
@media(max-width:900px){
    /* Navbar mobile */
    .navbar{
        height: auto;
    }
    .navbar .logo{
        height: 70px !important;
    }
    .navbar-content{
        display: flex;
        flex-direction: column;
    }
    .items{
        flex-direction: column;
        width: 70%;
        padding: 0;
        gap: 0rem !important;
        display: none !important;
    }
    #burger:checked ~ .items{
        display: flex !important;
    }
    .submenu{
        border: none;
    }
    .item:nth-child(1){
        padding: 25px;
    }
    .item{
        padding: 25px 0 0 0;
        width: 70% !important;
        flex-direction: column;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
    }
    .item .submenu{
        margin-top: 25px;
    }
    .submenu{
        position: static !important;
    }
    .submenu ul li{
        background: rgb(63, 63, 63);
        border: none;
    }
    .burger{
        margin: 15px 0;
    }
    .navbar-margin{
        margin-top: 20vh;
    }
    .card-container{
        justify-content: center;
    }
}
@media(max-width:600px){
    .product-card{
        width: auto;
    }
    .carousel{
        height: 250px !important;
    }
}
@media(max-width:600px){
    .navbar .logo{
        height: 50px !important;
    }
    .items{
        flex-direction: column;
        width: 100%;
        padding: 0;
        gap: 0rem !important;
    }
    .image-card-container img{
        object-fit:contain !important;
    }
}

@media(max-width:400px){
    .underline{ 
        margin: 0 auto;
    }
    .underline::after,.underline::before{
        width: 100%;
    }
}
/* FONTS MEDIA QUERYS */
@media(max-width:1500px){
    .fs-5{
        font-size: 1rem !important;
    }
    .fs-4{
        font-size: 1.25rem !important;
    }
    .fs-3{
        font-size: 1.5rem !important
    }
}
@media(max-width:1200px){
    .fs-5{
        font-size: 1rem !important;
    }
    .fs-4{
        font-size: 1.15rem !important;
    }
    .fs-3{
        font-size: 1.25rem !important
    }   
}
@media(max-width:900px){
    .fs-5{
        font-size: 0.85rem !important;
    }
    .fs-4{
        font-size: 1rem !important;
    }
    .fs-3{
        font-size: 1.15rem !important
    }
}